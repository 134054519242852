import request from '@/utils/request'

/* 获取通知 */
export function noticeList(data) {
	return request({
		url: '/system/userNotice/myList',
		method: 'get',
		params: data
	})
}
/* 全部标记为已读 */
export function readAllNotice(data) {
	return request({
		url: '/system/userNotice/readAllNotice',
		method: 'put'
	})
}
/* 全部标记为已读 */
export function readNotice(data) {
	return request({
		url: '/system/userNotice',
		method: 'put',
		data
	})
}
/* 删除通知 */
export function removeNotice(data) {
	return request({
		url: '/system/userNotice/' + data,
		method: 'delete'
	})
}

/* 获取系统设置 */
export function configKey(key) {
	return request({
		url: '/system/config/configKey/' + key,
		method: 'get'
	})
}


/* 扫码获取内容 */
export function seeQrCode(data) {
	return request({
		url: '/portal/case/seeQrCode',
		method: 'get',
		headers: {
			isToken: false
		},
		params: data
	})
}

/* 获取系统公告 */
export function getNotice(data) {
	return request({
		url: '/system/notice/list',
		method: 'get',
		params: data
	})
}
/* 获取字典数据 */
export function getDictData(type) {
	return request({
		url: '/system/dict/data/type/'+type,
		method: 'get'
	})
}