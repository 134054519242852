<!--树形穿梭框组件-->
<!--官网:https://www.npmjs.com/package/el-tree-transfer-->
<template>
  <div class="store-tree-select store-tree-wrap">
    <tree-transfer
        :width="width"
        :height="height"
        :openAll="openAll"
        :filter="filter"
        :pid="pid"
        :node_key="nodeKey"
        @add-btn='addTreeTransfer'
        @remove-btn='removeTreeTransfer'
        :defaultProps="defaultProps"
        :placeholder="placeholder"
        :title="title"
        :button_text="buttonText"
        :mode="mode"
        :from_data='fromData'
        :to_data='toData'
        :defaultCheckedKeys="defaultCheckedKeys"
        :defaultTransfer="defaultTransfer"
        ref="transfer"
        @left-check-change="onLeftChange"
        @right-check-change="onRightChange"
    >

    </tree-transfer>
  </div>
</template>

<script>
import treeTransfer from 'el-tree-transfer'
export default {
  components: {
    treeTransfer
  },
  props:{
    // 宽度
    width:{
      default: ()=>{
        return '100%'
      }
    },
    // 高度
    height:{
      default: ()=>{ return '320px' }
    },
    // 设置左右两边的标题`
    title:{
      default: ()=>{return ["可选列表", "已选列表"]}
    },
    // 设置按钮文案
    buttonText:{
      default: ()=>{return []}
    },
    // 左边数据
    fromData:{
      default: ()=>{return []}
    },
    // 右边数据
    toData:{
      default: ()=>{return []}
    },
    //配置项-同el-tree中props
    defaultProps:{
      default: ()=>{return { label: "label", children: "children", isLeaf: "leaf", disable: "disable" }}
    },
    //自定义node-key的值，默认为id
    nodeKey:{
      default: ()=>{return 'id'}
    },
    //自定义pid的参数名，默认为"pid"
    pid:{
      default: ()=>{return 'parentId'}
    },
    //是否开启筛选功能
    filter:{
      default: ()=>{return true}
    },
    //是否默认展开全部
    openAll:{
      default: ()=>{return false}
    },
    //mode默认为transfer模式，即树形穿梭框模式，可配置字段为addressList改为通讯录模式，通讯录模式时按钮不可自定义名字，如要自定义标题名在title数组传入四个值即可，addressList模式时标题默认为通讯录、收件人、抄送人、密送人
    mode:{
      default: ()=>{return 'transfer'}
    },
    //穿梭后是否展开穿梭的节点
    transferOpenNode:{
      default: ()=>{return true}
    },
    //默认选中节点
    defaultCheckedKeys:{
      default: ()=>{return []}
    },
    //是否自动穿梭一次默认选中
    defaultTransfer:{
      default: ()=>{return true}
    },
    //设置搜索框提示语
    placeholder:{
      default: ()=>{return '输入关键词进行筛选'}
    },
  },
  created() {
  },
  methods:{

    // 输穿梭框监听事件
    removeTreeTransfer(fromData,toData,obj){
      this.$emit('removeBtn',fromData,toData,obj)
    },
    addTreeTransfer(fromData,toData,obj){
      this.$emit('addBtn',fromData,toData,obj)
    },


    //过滤并全选节点后，添加或移除选项会将未过滤节点同步到另一侧问题
    onLeftChange() {
      const treeTransfer = this.$refs.transfer.$refs['wl-transfer-component'];
      const fromTree = treeTransfer.$refs['from-tree'];
      const fromTreeCheckedKeys = fromTree.getCheckedKeys(true);
      const _fromTreeCheckedKeys = fromTreeCheckedKeys.filter(node => {
        return fromTree.getNode(node).visible;
      });
      fromTree.setCheckedKeys(_fromTreeCheckedKeys);
    },
    onRightChange() {
      const treeTransfer = this.$refs.transfer.$refs['wl-transfer-component'];
      const toTree = treeTransfer.$refs['to-tree'];
      const toTreeCheckedKeys = toTree.getCheckedKeys(true);
      const _toTreeCheckedKeys = toTreeCheckedKeys.filter(node => {
        return toTree.getNode(node).visible;
      });
      toTree.setCheckedKeys(_toTreeCheckedKeys);
    },
  }
}
</script>
<style scoped>
	::v-deep .transfer-main{
		padding-bottom: 60px !important;
	}
</style>

