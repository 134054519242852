import {
	login,
	logout,
	getUserInfo
} from '@/api/user'
import {
	getToken,
	setToken,
	removeToken,
	setKey,
	getKey
} from '@/utils/auth'
import {
	resetRouter
} from '@/router'

const getDefaultState = () => {
	return {
		token: getToken(),
		nickName: '',
		avatar: '',
		key: getKey(),
		userId:''
	}
}

const state = getDefaultState()

const mutations = {
	RESET_STATE: (state) => {
		Object.assign(state, getDefaultState())
	},
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_NICKNAME: (state, name) => {
		state.nickName = name
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_KEY: (state, data) => {
		state.key = data
	},
	SET_USERID: (state, data) => {
		state.userId = data
	},
}

const actions = {
	// user login
	// login(userInfo) {
	login({
		commit
	}, userInfo) {
		// const { phone, password,uuid} = userInfo;
		// console.log(userInfo);
		const phone = userInfo.phone.trim()
		const password = userInfo.password
		const code = userInfo.smscode
		const uuid = userInfo.uuid
		return new Promise((resolve, reject) => {
			login(phone, password, code, uuid).then(response => {
				const {
					data
				} = response;
				commit('SET_TOKEN', data.access_token);
				commit('SET_KEY', data.key);
				setKey(data.key);
				setToken(data.access_token);
				resolve()
				var pars = {
					type: 'login',
					pars: {
						id: data.key
					}
				};
				userInfo.that.$socketPublic.dispatch('webSocketSend', pars); //初始化ws
			}).catch(error => {
				reject(error)
			})
		})
	},

	// get user info
	getInfo({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			getUserInfo(state.token).then(response => {
				if (response.code != 200) {
					return reject('Verification failed, please Login again.')
				}
				console.log("获取用户信息--",response.user);
				commit('SET_NICKNAME', response.user.nickName)
				commit('SET_AVATAR', response.user.avatar)
				commit('SET_USERID', response.user.userId)
				resolve(response)
			}).catch(error => {
				reject(error)
			})
		})
	},

	// user logout
	logout({
		commit,
		state
	}) {
		return new Promise((resolve, reject) => {
			logout(state.token).then(() => {
				removeToken() // must remove  token  first
				resetRouter()
				commit('RESET_STATE')
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

	// remove token
	resetToken({
		commit
	}) {
		return new Promise(resolve => {
			removeToken() // must remove  token  first
			commit('RESET_STATE')
			resolve()
		})
	}
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}