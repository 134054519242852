<template>
	<div style="display: flex;">
		<el-select :disabled="isDisdable" placeholder="请选择省份" v-model="form.province" @change="(e)=>{getAreaList(e,1)}">
			<el-option v-for="item,index in areaList[0]" :key="index" :value="item.id" :label="item.name"></el-option>
		</el-select>
		<el-select :disabled="isDisdable" style="margin-left: 15px;" placeholder="请选择城市" v-model="form.city"
			@change="(e)=>{getAreaList(e,2)}">
			<el-option v-for="item,index in areaList[1]" :key="index" :value="item.id" :label="item.name"></el-option>
		</el-select>
		<el-select :disabled="isDisdable" style="margin-left: 15px;" placeholder="请选择区" v-model="form.area"
			@change="(e)=>{getAreaList(e,3)}">
			<el-option v-for="item,index in areaList[2]" :key="index" :value="item.id" :label="item.name"></el-option>
		</el-select>
	</div>
</template>
<script>
	import {
		listArea
	} from '@/api/area.js'
	export default {
		name: "AddressSel",
		data() {
			return {
				location: window.location.origin,
				// 城市数组
				areaList: [
					[],
					[],
					[],
				],
				form: {
					province: '',
					city: '',
					area: '',
					street: ''
				},
				init: true
			}
		},
		props: {
			/* 是否需要默认值填充 */
			join: {
				type: Boolean,
				default: true
			},
			/* 省市区的值 */
			default: {
				type: Array,
				default: [0, 0, 0]
			},
			/* 是否禁止选择 */
			isDisdable: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			// default: {
			// 	handler(a, b) {

			// 	},
			// 	deep: true,
			// 	immediate: false
			// }

		},
		mounted() {
			console.log("default", this.default);
			if (this.default && this.default[0] != 0) {
				 this.form.province = !this.default[0]?"":parseInt(this.default[0]);
				 this.form.city = !this.default[1]?"":parseInt(this.default[1]);
				 this.form.area = !this.default[2]?"":parseInt(this.default[2]);
			}
			this.getAreaList(0, 0);
			this.$forceUpdate()
		},
		methods: {
			getAreaList(id, level) {
				listArea({
					pid: id,
					pageNum: 1,
					pageSize: 10000
				}).then(res => {
					this.areaList[level] = res.rows;
					if (level < 4) {
						if (this.init && ((level == 0 && this.form.province != '') || (level == 1 && this.form
								.city != ''))) {
							this.getAreaList(level == 0 ? this.form.province : level == 1 ? this.form.city : this
								.form.area,
								level + 1);
						} else {
							this.getAreaList(this.areaList[level][0].id, level + 1);
						}
					} else {
						this.init = false;
					}
					//是否需要默认值填充，join为true并且在没有默认值的时候才可以
					if (this.join && !(this.init && this.default && this.default[0] && this.default[1] && this
							.default[2])) {
						if (level == 0) {
							this.form.province = this.areaList[0].length > 0 ? this.areaList[0][0].id : "";
						}
						if (level == 1) {
							this.form.city = this.areaList[1].length > 0 ? this.areaList[1][0].id : "";
						}
						if (level == 2) {
							this.form.area = this.areaList[2].length > 0 ? this.areaList[2][0].id : "";
						}
						// if (level == 3) {
						// 	this.form.street = this.areaList[3].length > 0 ? this.areaList[3][0].id : "";
						// }
						this.setAddress();
					}
					this.$forceUpdate();

				})
			},
			reset() { //重置选中值
				console.log("重置选中值");
				this.form.province = "";
				this.form.city = "";
				this.form.area = "";
				this.form.street = "";
			},
			setAddress(loc) {
				//这里就能得到loc了
				this.$emit("selAddress", [this.form.province, this.form.city, this.form.area, this.form.street]);
			}
		},
	}
</script>

<style>
</style>