/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isEmpty(str) {
  if (typeof str === 'undefined') {
  	return true;
  } else if (str == null) {
  	return true;
  } else if (str == 'null') {
  	return true;
  } else if (str == '') {
  	return true;
  }
  return false;
}
