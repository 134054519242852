import request from '@/utils/request'


// 登录方法
export function login(phone, password, code, uuid) {
	return request({
		url: '/auth/login',
		headers: {
			isToken: false
		},
		method: 'post',
		data: {
			phone,
			password,
			code,
			uuid,
			loginType: '01',
			loading: true
		}
	})
}
// 获取短信验证码
export function sendSmsToPhone(data) {
	return request({
		url: '/portal/public/sendSmsToPhone',
		headers: {
			isToken: false
		},
		method: 'post',
		data: data
	})
}


/* 获取当前登录用户信息 */
export function getUserInfo() {
	return request({
		url: 'system/user/getInfo',
		method: 'get',
	})
}

export function logout() {
	return request({
		url: '/user/logout',
		method: 'post'
	})
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
	const data = {
		oldPassword,
		newPassword
	}
	return request({
		url: '/system/user/profile/updatePwd',
		method: 'put',
		params: data
	})
}


// 用户头像上传
export function uploadAvatar(data) {
	return request({
		url: '/system/user/profile/avatar',
		method: 'post',
		data: data,
		type: "formData"
	})
}

// 查询专业下拉树结构
export function treeselect() {
	return request({
		url: '/system/majorType/treeselect',
		method: 'get'
	})
}


// 修改用户
export function updateUser(data) {
	return request({
		url: '/system/user',
		method: 'put',
		data: data
	})
}


/* 获取用户留言列表 */
export function leaveMessageList(data) {
	return request({
		url: '/portal/leaveMessageList',
		method: 'get',
		params: data
	})
}

/* 回复 */
export function leaveMessageEdit(data) {
	return request({
		url: '/portal/leaveMessageEdit',
		method: 'put',
		data: data
	})
}