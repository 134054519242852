<template>
	<div>
		<el-container>
			<el-header class="el-header">
				<home-header></home-header>
			</el-header>
			<el-container>
				<el-aside width="355px">
					<left-menu></left-menu>
				</el-aside>
				<el-main>
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
		<bottomView></bottomView>
	</div>
</template>

<script>
	import bottomView from "@/components/bottomView"
	import HomeHeader from '@/components/Header'
	import LeftMenu from '@/components/LeftMenu'
	export default {
		name: 'layout',
		components: {
			HomeHeader,
			LeftMenu,
			bottomView
		}
	}
</script>

<style lang="scss" scoped>
	.el-header {
		height: 80px !important;
		background-color: #ff5500;
		padding: 0 !important;
	}

	::v-deep .el-main {
		min-height: calc(100vh - 140px) !important;
	}

	.el-container {
		// height: calc(100vh - 200px) !important;
		background-color: #fafafa;
	}

	.bottom-container {
		width: 100%;
		height: 50px;
		background-color: #EEEEEE;
		// position: fixed;
		bottom: 0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 9999;
	}

	.bottom-text {
		margin-left: 30px;
		margin-right: 30px;
		font-size: 14px;
		color: #828282;
	}

	::v-deep .el-aside {
		.el-scrollbar__wrap {
			overflow: hidden;
		}
	}

	::v-deep.el-scrollbar__bar.is-horizontal {
		height: 0 !important;
		display: none;
	}
</style>