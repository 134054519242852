import Cookies from 'js-cookie'

const TokenKey = 'account_token_lawyer'
const loginKey = 'account_key_lawyer'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


/* 设置key存储 */
export function setKey(data) {
   return Cookies.set(loginKey, data)
}

/* 获取key存储 */
export function getKey(name) {
  return Cookies.get(loginKey)
}

/* 删除key存储 */
export function removeKey() {
  return Cookies.remove(loginKey)
}

