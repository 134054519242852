import request from '@/utils/request'
//获取案件列表
export function getClaimsList(data) {
	return request({
		url: 'case/litigation/getList',
		method: 'get',
		params: data
	})
}
//获取案由设置
export function getBriefList(data) {
	return request({
		url: "case/actionCause/getBriefList/getListByProjectType",
		method: 'get',
		params: data
	})
}

//获取律师列表
export function getLawyerList(data) {
	return request({
		url: 'system/user/getLawyerList',
		method: 'get',
		params: data
	})
}
//获取专委会律师列表
export function getManegeList(data) {
	return request({
		url: 'system/user/manegeList',
		method: 'get',
		params: data
	})
}
//发起项目
export function addLitigation(data) {
	return request({
		url: 'case/litigation',
		method: 'post',
		data: data
	})
}
//获取介入阶段
export function getLawyerStageList(data) {
	return request({
		url: 'case/lawyerStage/list',
		method: 'get',
		params: data
	})
}


/* 获取诉讼项目详情 */
export function getlitigationInfo(id) {
	return request({
		url: 'case/litigation/' + id,
		method: 'get'
	})
}
/* 修改诉讼项目-用于驳回后重新提交 */
export function editlitigationInfo(data) {
	return request({
		url: 'case/litigation/edit',
		method: 'post',
		data: data
	})
}
/* 完成收案 */
export function endReceiveCase(data) {
	return request({
		url: 'case/litigation/endReceiveCase',
		method: 'post',
		params: data
	})
}
/* 修改诉讼项目-用于驳回后重新提交 */
export function getLitigationFilesList(data) {
	return request({
		url: 'case/litigation/edit',
		method: 'post',
		data: data
	})
}

/* 修改诉讼项目-用于驳回后重新提交 */
export function getLitigationFilesListSize(data) {
	return request({
		url: 'case/litigationFiles/edit',
		method: 'post',
		data: data
	})
}
/* 获取业务文件 */
export function getLitigationSpeed(data) {
	return request({
		url: 'case/litigation/getLitigationSpeed',
		method: 'get',
		params: data
	})
}
/* 获取业务文件列表 */
export function getFileList(data) {
	return request({
		url: 'case/litigationFiles/list',
		method: 'get',
		params: data
	})
}
/* 添加业务文件 */
export function addFile(data) {
	return request({
		url: 'case/litigationFiles',
		method: 'post',
		data: data
	})
}
/* 删除业务文件 */
export function deleteFile(id) {
	return request({
		url: 'case/litigationFiles/' + id,
		method: 'delete',
	})
}


/* 获取委托协议申请列表 */
export function getLitigationContractList(data) {
	return request({
		url: 'case/litigationContract/list',
		method: 'get',
		params: data
	})
}
/* 获取委托协议申请列表详细 */
export function getwtContractDetail(id) {
	return request({
		url: 'case/litigationContract/' + id,
		method: 'get'
	})
}
/* 添加委托协议合同申请 */
export function addLitigationContract(data) {
	return request({
		url: 'case/litigationContract',
		method: 'post',
		data: data
	})
}
/* 添加委托协议合同申请 */
export function deleteContract(id) {
	return request({
		url: 'case/litigationContract/' + id,
		method: 'delete',
	})
}
/* 完成办案 */
export function setEndCompleteCase(data) {
	return request({
		url: 'case/litigation/endCompleteCase',
		method: 'post',
		params: data
	})
}

/* 添加会见函 */
export function addBusLitigationMeeting(data) {
	return request({
		url: 'case/litigationMeeting',
		method: 'post',
		data: data
	})
}
/* 修改会见函 */
export function editLitigationMeeting(data) {
	return request({
		url: 'case/litigationMeeting',
		method: 'put',
		data: data
	})
}
/* 获取会见函列表 */
export function getBusLitigationMeetingList(data) {
	return request({
		url: 'case/litigationMeeting/listAsc',
		method: 'get',
		params: data
	})
}
/* 删除会见函列表 */
export function dellistAsc(id) {
	return request({
		url: 'case/litigationMeeting/' + id,
		method: 'delete'
	})
}
/* 模板下载--获取模板列表 */
export function getContractTempList(data) {
	return request({
		url: 'case/contractTemp/list',
		method: 'get',
		params: data
	})
}

/* 模板下载--获取类型列表详细 */
export function getContractTempDetail(id) {
	return request({
		url: 'case/contractTemp/' + id,
		method: 'get'
	})
}

/* 模板下载--获取类型列表 */
export function getContractTypeList(data) {
	return request({
		url: 'case/contractType/list',
		method: 'get',
		params: data
	})
}

/* 获取当前登录用户信息 */
export function getUserInfo() {
	return request({
		url: 'system/user/getInfo',
		method: 'get',
	})
}

/* 获取收集证据清单 */
export function getLitigationEvidenceList(data) {
	return request({
		url: 'case/litigationEvidence/list',
		method: 'get',
		params: data
	})
}
/* 获取收集证据清单导出 */
export function exportLitigationEvidenceList(data) {
	return request({
		url: 'case/litigationEvidence/export',
		method: 'get',
		params: data
	})
}
/* 添加收集证据清单 */
export function addLitigationEvidence(data) {
	return request({
		url: 'case/litigationEvidence',
		method: 'post',
		data: data
	})
}
/* 编辑收集证据清单 */
export function editLitigationEvidence(data) {
	return request({
		url: 'case/litigationEvidence',
		method: 'put',
		data: data
	})
}
/* 删除收集证据清单 */
export function deleteLitigationEvidence(id) {
	return request({
		url: 'case/litigationEvidence/' + id,
		method: 'delete'
	})
}
/* 根据用户获取集体讨论 */
export function getIscussionByUser(data) {
	return request({
		url: 'case/litigationIscussion/getIscussionByUser',
		method: 'get',
		params: data
	})
}
/* 提交集体讨论 */
export function submitIscussion(data) {
	return request({
		url: 'case/litigationIscussion',
		method: 'post',
		data: data
	})
}


/* 赞同不赞同讨论观点 */
export function updateViewpoint(data) {
	return request({
		url: 'case/litigationIscussion/updateStatus',
		method: 'post',
		params: data
	})
}


/* 添加案件一览表 */
export function addArchives(data) {
	return request({
		url: 'case/litigationArchives',
		method: 'post',
		data: data
	})
}

/* 获取案件一览表 */
export function getArchives(caseId) {
	return request({
		url: 'case/litigationArchives/' + caseId,
		method: 'get',
	})
}
/* 提交封面信息 */
export function submitCoverInfo(data) {
	return request({
		url: 'case/litigationArchives/saveCoverInfo',
		method: 'post',
		data: data
	})
}

/* 修改律师占比 */
export function updateCaseLawyerRatio(data) {
	return request({
		url: 'case/litigation/updateLawyerEndRatio',
		method: 'post',
		data: data
	})
}
/* 提交结案审批 */
export function submitApproval(data) {
	return request({
		url: 'case/litigationArchives/submitApproval',
		method: 'post',
		params: data
	})
}
/* 修改质管律师 */
export function updateQualityLawyer(data) {
	return request({
		url: 'case/litigation/updateQualityLawyer',
		method: 'post',
		params: data
	})
}
/* 结案审批 */
export function submitEditApproval(data) {
	return request({
		url: 'case/litigationArchives/editApproval',
		method: 'post',
		params: data
	})
}

/* 结案报告 */
export function closingReport(data) {
	return request({
		url: 'case/litigationArchives/closingReport',
		method: 'post',
		params: data
	})
}

// 查询诉讼案件类型菜单列表
export function listByTree(data) {
	return request({
		url: 'case/litigationMenu/listByTree',
		method: 'get',
		params: data
	})
}


// 获取查看或下载文件路径
export function getFileUrl(data) {
	return request({
		url: 'case/file/getFileUrl',
		method: 'get',
		params: data
	})
}
//获取调查函最新编号
export function getMeetingCaseNo() {
	return request({
		url: 'case/litigationMeeting/getMeetingCaseNo',
		method: 'get',
	})
}

//完成结案 参数id
export function busLitigationEnd(data) {
	return request({
		url: 'case/litigation/busLitigationEnd',
		method: 'post',
		params: data
	})
}

//修改文件名称 fileName
export function updateFileName(data) {
	return request({
		url: 'case/litigationFiles',
		method: 'put',
		data
	})
}

/* 缓存未提交的收案数据 */
export function temporaryStorage(data) {
	return request({
		url: 'case/litigation/temporaryStorage',
		method: 'post',
		data: data
	})
}

/* 获取缓存未提交的收案数据 */
export function getTemporaryStorage(data) {
	return request({
		url: 'case/litigation/getTemporaryStorage',
		method: 'get',
		params: data
	})
}

