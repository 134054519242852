import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [{
		path: '/login',
		component: () => import('@/views/login/index'),
		hidden: true
	},
	{
		path: '/pdfCodeView',
		component: () => import('@/views/pdfCodeView'),
		hidden: true
	},
	{
		path: "/",
		name: "Layout",
		component: Layout,
		children: [{
				//默认 显示home组件
				path: "",
				name: "Home",
				component: () => import("../views/home/index.vue")
			}, {
				path: "/home",
				name: "Home",
				component: () => import("../views/home/index.vue") //首页
			},
			{
				path: "/claims",
				name: "claims",
				component: () => import('@/views/claims-platform/index'),//诉讼平台
				meta: { keepAlive: true }
			},
			{
				path: "/no-claims",
				name: "no-claims",
				component: () => import('@/views/no-claims-platform/index') //非诉讼平台
			},
			{
				path: "/knowledgeBase",
				name: "knowledgeBase",
				component: () => import('@/views/knowledgeBase/index') //知识库
			},
			{
				path: "/orther",
				name: "orther",
				component: () => import('@/views/orther/index') //其他事务
			},
			{
				path: "/client",
				name: "client",
				component: () => import('@/views/client/index') //我负责的客户
			},
			{
				path: '*',
				redirect: '/404',
				hidden: true
			},
			/* 民事案件详细 */
			{
				path: '/detail',
				name:"detail",
				component: () => import('@/views/claims-platform/detail/detail.vue'),
				hidden: true
			},
			/* 刑事案件详细 */
			{
				path: '/crown_detail',
				name:"crownDetail",
				component: () => import('@/views/claims-platform/detail/crown_detail.vue'),
				hidden: true
			},
			/* 仲裁案件详细 */
			{
				path: '/arbitration_detail',
				name:"arbitrationDetail",
				component: () => import('@/views/claims-platform/detail/arbitration_detail.vue'),
				hidden: true
			},
			/* 行政案件详细 */
			{
				path: '/admin_detail',
				name:"adminDetail",
				component: () => import('@/views/claims-platform/detail/admin_detail.vue'),
				hidden: true
			},
			/* 民事案件新增 */
			{
				path: '/addCivilCase',
				name:"addCivilCase",
				component: () => import('@/views/claims-platform/addCase/civilCase.vue'),
				hidden: true
			},
			/* 刑事案件新增 */
			{
				path: '/addCrownCase',
				name:"addCrownCase",
				component: () => import('@/views/claims-platform/addCase/crownCase.vue'),
				hidden: true
			},
			/* 仲裁案件新增 */
			{
				path: '/addArbitrationCase',
				name:"addArbitrationCase",
				component: () => import('@/views/claims-platform/addCase/arbitrationCase.vue'),
				hidden: true
			},
			/* 行政案件新增 */
			{
				path: '/addAdminCase',
				name:"addAdminCase",
				component: () => import('@/views/claims-platform/addCase/adminCase.vue'),
				hidden: true
			},
			/* 收案 */
			{
				path: '/checkIndex',
				name:"checkIndex",
				component: () => import('@/views/claims-platform/check-new/index.vue'),
				hidden: true
			},
		],
	},
]

// export const constantRoutes = [
// 	{
// 		path: '/',
// 		name:"login",
// 		component: () =>  import('@/views/login/index'),
// 	},
// 	{
// 		path: '/home',
// 		name:"Home",
// 		component: () =>  import('@/views/home/index'),
// 	},
// 	{
// 		path: '/claims',
// 		name:"claims",
// 		component: () => import('@/views/claims-platform/index'),
// 	},
// ]



const createRouter = () => new Router({
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
export default router