<template>
	<div class="app-wrapper">
		<div class="app-container">
			<el-scrollbar class="sidebar-container">
				<div class="menu-container">
					<!-- <router-link to="/claims"> -->
						<div class="menu-wrapper pointer" @click="gotoPage('/claims')">
							<div class="menu-item">
								<img class="menu-icon" :src="require('@/icons/svg/menu1.svg')" />
							</div>
							<p>诉讼业务工作台</p>
						</div>
					<!-- </router-link> -->
					<!-- <router-link to="/no-claims"> -->
						<div class="menu-wrapper pointer" @click="gotoPage('/no-claims')">
							<div class="menu-item menu2">
								<img class="menu-icon" :src="require('@/icons/svg/menu2.svg')" />
							</div>
							<p>非诉讼业务工作台</p>
						</div>
					<!-- </router-link> -->
					<!-- <router-link to="/knowledgeBase"> -->
						<div class="menu-wrapper pointer" @click="gotoPage('/knowledgeBase')">
							<div class="menu-item menu3">
								<img class="menu-icon" :src="require('@/icons/svg/menu3.svg')" />
							</div>
							<p>知识库</p>
						</div>
					<!-- </router-link> -->
					<!-- <router-link to="/orther"> -->
						<div class="menu-wrapper pointer" @click="gotoPage('/orther')">
							<div class="menu-item menu4">
								<img class="menu-icon" :src="require('@/icons/svg/menu4.svg')" />
							</div>
							<p>其他事务</p>
						</div>
					<!-- </router-link> -->
					<!-- <router-link to="/client"> -->
						<div class="menu-wrapper pointer" @click="gotoPage('/client')">
							<div class="menu-item menu5">
								<img class="menu-icon" :src="require('@/icons/svg/menu5.svg')" />
							</div>
							<p>我负责的客户</p>
						</div>
					<!-- </router-link> -->
				</div>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LeftMenu',
		computed: {
			fixedHeader() {
				return this.$store.state.settings.fixedHeader
			},
		},
		methods: {
			gotoPage(str) {
				if(this.$route.path == str){
					location.reload()
				}
				this.$router.push({
					path: str
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import "~@/styles/mixin.scss";
	@import "~@/styles/variables.scss";

	.sidebar-container {
		height: 100% !important;
	}

	.app-wrapper {
		position: relative;
		height: calc(100vh - 230px) !important;
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}

	.app-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
	}

	.menu-container {
		display: flex;
		flex-wrap: wrap;
	}

	.menu-wrapper {
		width: 140px;
		height: 150px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-left: 30px;
		margin-top: 20px;
	}

	.menu-item {
		width: 100px;
		height: 130px;
		position: relative;
		background: linear-gradient(to bottom, #3e6cb1, #32569e);
		box-shadow: 0 1px 4px rgba(0, 21, 41, .1);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-radius: 30px;

		.menu-icon {
			width: 68px;
			height: 68px;
			z-index: 100;
		}
	}

	.menu2 {
		background: linear-gradient(to bottom, #555cb2, #36428e);
	}

	.menu3 {
		background: linear-gradient(to bottom, #5fbc6c, #3f9463);
	}

	.menu4 {
		background: linear-gradient(to bottom, #5d7587, #546776);
	}

	.menu5 {
		background: linear-gradient(to bottom, #377c93, #206283);
	}
</style>
