const baseConfig = {
  production: 'http://www.api.lalala.com', // 线上 (生成环境)
  development: 'http://14.29.243.71:9001', // 本地 (开发环境)
  accessHomeData: 'http://www.xmyxapp.com' // 其他api
}


const baseUrl = process.env.NODE_ENV === 'production' ? baseConfig.production : baseConfig.development


export default baseUrl