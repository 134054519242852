import {
	Notification,
	MessageBox,
	Message,
	Loading
} from 'element-ui'
import axios from 'axios'
import {
	getFileUrl
} from '@/api/claims'
import { indexOf } from './user'
let funs = {
	getNowTime(str) {
		let dt = new Date()
		let y = dt.getFullYear()
		let mt = (dt.getMonth() + 1).toString().padStart(2, '0')
		let day = dt.getDate().toString().padStart(2, '0')
		let h = dt.getHours().toString().padStart(2, '0')
		let m = dt.getMinutes().toString().padStart(2, '0')

		let d = dt.getDay(); //  周一返回的是1，周六是6，但是周日是0
		let arr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六", ];
		let week = arr[d];
		let nowtime = "";
		if (str == 'yy-mm-dd') {
			nowtime = y + "-" + mt + "-" + day
		} else if (str == 'hh-mm') {
			nowtime = h + ":" + m
		} else if (str == 'mm-dd') {
			nowtime = mt + "-" + day
		} else if (str == 'week') {
			nowtime = week
		} else {
			nowtime = y + "-" + mt + "-" + day + " " + h + ":" + m
		}

		return nowtime;
		console.log('此刻时间', this.nowtime)
	},
	/* 获取屏幕高度 */
	getScreenHeight() {
		return document.body.clientHeight;
	},

	/* 时间格式化 */
	transformTimestamp(timestamp) {
		let a = new Date(timestamp).getTime();
		const date = new Date(a);
		const Y = date.getFullYear() + '-';
		const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
		const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
		const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
		const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()); // 秒
		const dateString = Y + M + D + h + m + s;
		// console.log('dateString', dateString); // > dateString 2021-07-06 14:23
		return dateString;
	},

	/* 随机数*/
	uuid(str) {
		var s = [];
		var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		for (var i = 0; i < 32; i++) {
			s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
		}
		s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
		s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
		s[8] = s[13] = s[18] = s[23];
		var uuid = s.join("");
		return uuid;
	},


	/* 文件下载*/
	downloadEvt(fileurl) {
		let fileName = fileurl.split('/').pop();
		if (!fileurl) {
			Message({
				message: '无文件可下载！',
				type: 'error'
			});
			return
		}
		Message({
			message: '正在下载.....',
			type: 'info'
		});

		if (fileurl.indexOf('http') != -1) {
			// 模拟文件下载链接
			const fileUrl = fileurl;
			// 创建下载链接
			const link = document.createElement('a');
			link.download = fileName; // 设置下载文件的名称
			document.body.appendChild(link);
			link.href = fileUrl;
			link.click();
			document.body.removeChild(link);
			return
		} else {
			getFileUrl({
				key: fileurl
			}).then(res => {
				// 模拟文件下载链接
				const fileUrl = res.data;
				// 创建下载链接
				setTimeout(function() {
					const link = document.createElement('a');
					link.download = fileName; // 设置下载文件的名称
					document.body.appendChild(link);
					link.href = fileUrl;
					link.click();
					document.body.removeChild(link);
				}, 100);
			})
		}
	},

	/* 获取当前日期 */
	getNowDate() {
		const timeOne = new Date()
		const year = timeOne.getFullYear()
		let month = timeOne.getMonth() + 1
		let day = timeOne.getDate()
		month = month < 10 ? '0' + month : month
		day = day < 10 ? '0' + day : day
		const NOW_MONTHS_AGO = `${year}-${month}-${day}`
		return NOW_MONTHS_AGO
	},
	/* 比较两个日期  value传入的时间*/
	timeShow(value) {
		value = new Date(value.replace(/-/g, "/"));
		let today = new Date(); //当前日期：2017-04-24
		var days = value.getTime() - today.getTime();
		var time = parseInt(days / (1000 * 60 * 60 * 24));
		console.log("比较日期", today, value, time);
		if (time == 0 || time > 0) {
			return time
		} else {
			return '-1'
		}
	},


	/* 截取文件后缀名 */
	fileLastSuffix(url) {
		if (url) {
			let str;
			if (url && url.indexOf("/") > -1) {
				let pos = url.lastIndexOf('/') // '/'所在的最后位置
				str = url.substr(pos + 1) //截取文件名称和后缀   输出：香菜.png
			} else {
				str = url // '/'所在的最后位置
			}
			let name = str.substring(str.lastIndexOf('.') + 1)
			return name;
		} else {
			return ''
		}

	},

	/* 获取文件名 */
	getFileName(url) {
		if (url) {
			let file = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
			console.log(file);
			return file
		} else {
			return ""
		}

	},
	isObj(object) { // 判断是否是object
		if (JSON.stringify(object) == '{}') {
			return false
		} else {
			return true
		}
	},

	// 验证手机号码
	checkPhone(tel) {
		if (!tel) {
			return Message({
				message: '请输入！',
				type: 'error'
			});
		}
		var rtn = false;
		//移动号段
		var regtel = /^((13[4-9])|(15([0-2]|[7-9]))|(18[2|3|4|7|8])|(178)|(147))[\d]{8}$/;
		if (regtel.test(tel)) {
			rtn = true;
		}
		//电信号段
		regtel = /^((133)|(153)|(18[0|1|9])|(177))[\d]{8}$/;
		if (regtel.test(tel)) {
			rtn = true;
		}
		//联通号段
		regtel = /^((13[0-2])|(145)|(15[5-6])|(176)|(18[5-6]))[\d]{8}$/;
		if (regtel.test(tel)) {
			rtn = true;
		}
		if (!rtn) {
			Message({
				message: '请填写正确的手机号码！',
				type: 'error'
			});
		}
		return rtn;
	},

	/* 身份证验证 */
	checkIdNum(value) {
		// const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/

		const reg = /^\w{18}$/
		if (!value) {
			Message({
				message: '证件号码不能为空！',
				type: 'error'
			});
			return false
		} else if (!reg.test(value)) {
			Message({
				message: '证件号码不正确！',
				type: 'error'
			});
			return false
		} else {
			return true
		}
	},
	/* 手机号规则 */
	validatePhone(rule, value, callback) {
		const mobilePattern = /^1\d{10}$/;
		const landlinePattern = /^\d{3,4}-\d{7,8}$/;

		if (!mobilePattern.test(value) && !landlinePattern.test(value)) {
			callback(new Error('请输入有效的手机号或座机号'));
		} else {
			callback();
		}
	},
	/* 身份证规则 */
	validateIdCard(rule, value, callback) {
		if (!value || value.length !== 18) {
			callback(new Error('统一社会信用代码/身份证号码不正确'));
		} else {
			callback();
		}
	},
	// validateIdCard(rule, value, callback) {
	// 	// 正则表达式验证身份证号码
	// 	const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
	// 	if (!value) {
	// 		callback(new Error('请输入身份证号码'));
	// 	} else if (!reg.test(value)) {
	// 		callback(new Error('身份证号码格式不正确'));
	// 	} else {
	// 		callback();
	// 	}
	// },
	/* 统一社会信用代码规则 */
	validateCreditCode(rule, value, callback) {
		if (!value) {
			callback(new Error('请输入'));
		} else if (value.length !== 18) {
			callback(new Error('统一社会信用代码长度不正确'));
		} else {
			const pattern = /^[1-9A-HJ-NPQRTUWXY]\d{16}[0-9A-HJ-NPQRTUWXY]$/;
			if (pattern.test(value)) {
				callback();
			} else {
				callback(new Error('统一社会信用代码格式不正确'));
			}
		}
	},
	/* 校验手机号 */
	getPhoneRules(index) {
		return [{
				required: true,
				message: '请输入',
				trigger: 'blur'
			},
			{
				validator: (rule, value, callback) => funs.validatePhone(rule, value, callback, index),
				trigger: 'blur'
			}
		]
	},
	/* 校验身份证 */
	getIdCardRules(index, text) {
		return [{
				required: true,
				message: '请输入',
				trigger: 'blur'
			},
			{
				validator: (rule, value, callback) => funs.validateIdCard(rule, value, callback, index, text),
				trigger: 'blur'
			}
		]
	},
	/* 校验统一信用代码 */
	getCreditCodeRules(index) {
		return [{
				required: true,
				message: '请输入',
				trigger: 'blur'
			},
			{
				validator: (rule, value, callback) => funs.validateCreditCode(rule, value, callback, index),
				trigger: 'blur'
			}
		]
	},
	/* 去重 */
	noRepeat(arr) {
		var newArr = [...new Set(arr)]; //利用了Set结构不能接收重复数据的特点
		return newArr
	},
	deRepeat(arr) {
		let obj = {};
		arr.forEach((item, index) => {
			// 若重复则删除该项
			if (obj.hasOwnProperty(item.id)) {
				arr.splice(index, 1);
				// 不重复则存入obj
			} else {
				obj[item.id] = item.name;
			}
		});
		return arr;
	},
	/* 判断是否为空 */
	isNull(str) {
		if (str == '' || str == undefined || str == null) {
			return false
		} else {
			return true
		}
	},
	/* 文件预览 */
	preview(fileurl, fileName) {
		if (!fileurl) {
			Message({
				message: '文件错误，无法打开！',
				type: 'error'
			})
			return
		}
		let typeArr = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'jpg', 'jpeg', 'png', 'pdf'];
		let imgArr = ['pdf', 'jpg', 'jpeg', 'png', 'pdf'];
		let fileType = funs.fileLastSuffix(fileName ? fileName : fileurl);
		let url = '';
		if (typeArr.includes(fileType)) {
			// 使用微软的office online
			if (imgArr.includes(funs.fileLastSuffix(fileType))) {
				url = 'https://api.boot.jeecg.com/generic/web/viewer.html?file=' + encodeURIComponent(fileurl);

			} else {
				url = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(fileurl);
			}
			let width = 1000;
			const height = 800;
			let top = (window.screen.availHeight - height) / 2;
			let left = (window.screen.availWidth - width) / 2;
			window.open(url, '', 'width=' + width + ',height=' + height + ',top=' + top + ',left=' +
				left);
		} else {
			Message({
				message: '文件错误，无法打开！',
				type: 'error'
			})
			return
		}
	},
	/* 文件预览 */
	preview2(keys) {
		if (!keys) {
			Message({
				message: '文件错误，无法打开！',
				type: 'error'
			})
			return
		}
		let typeArr = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf', 'jpg', 'jpeg', 'png'];
		let newArr = ['jpg', 'png', 'jpeg', 'pdf']
		getFileUrl({
			key: keys
		}).then(res => {
			let url = '';
			let fileurl = res.data;
			console.log("url", fileurl);
			if (newArr.includes(keys) != -1) {
				url = fileurl
				// let pdfUrl = window.URL.createObjectURL(new Blob([fileurl], { type: "application/pdf" }))
				// window.open(pdfUrl);
			} else {
				url = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(fileurl);
			}
			let width = 1000;
			const height = 800;
			let top = (window.screen.availHeight - height) / 2;
			let left = (window.screen.availWidth - width) / 2;
			window.open(url, '', 'width=' + width + ',height=' + height + ',top=' + top + ',left=' +
				left);

		})
	},
	/* 图片预览 */
	imgPreview(keys) {
		let list = [];
		list.push(getPdfFileUrl(keys))
		console.log("imglist", list);
	},

	/* 获取文件路径 */
	getFileUrl(fileurl) {
		return new Promise((resolve, reject) => {
			getFileUrl({
				key: fileurl
			}).then(res => {
				resolve(res.data)
			})
		})
	},
	/* 获取路径 */
	async getPdfFileUrl(keys) {
		try {
			const response = await getFileUrl({
				key: keys
			});
			return response.data;
		} catch (error) {
			throw error;
		}
	},
	/* 获取路径 */
	getImgUrl(keys) {
		const response = getFileUrl({
			key: keys
		});
		console.log("[][][][][]", response);
		return response.data;
	},
	/* 去掉字符串最后的符号 */
	splicStr(str) {
		let newStr = str.slice(0, -1);
		return newStr
	},
	/* 获取案件类型 */
	getProjectType(str) {
		if (str == '1') {
			return '民事案件'
		} else if (str == '2') {
			return '刑事案件'
		} else if (str == '3') {
			return '行政案件'
		} else {
			return '仲裁案件'
		}
	},


	/* 金额转成大写 */
	moneyToCaptital(money) {
		money = money.toString()
		console.log("金额转成大写",money);
		if(!money){
			return
		}
		if(money==='0'){
			return '零元'
		}
		if (isNaN(money)) {
			Message({
				message: '请输入有效的数字',
				type: 'error'
			})
			return;
		}
		//将小数点前后划分开
		let dots = money.split('.')
		let dotFor = dots[0]
		let dotBehind = dots[1]
		//小数点前一部分，先反转一下，如'123'变成‘321’
		money = dotFor?dotFor.split('').reverse().join(''):money
		const cap = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖")
		const unit = new Array("", "拾", "佰", "仟")
		const lunit = new Array("", "万", "亿", "兆")
		const len = money.length
		const level = Math.ceil(len / 4)
		let res = ''
		//每4位数作为一次计算，如'1234444'读作一百二十三万四千四百四十四，反转后变为4444,321，从后开始往前计算
		for (let i = level - 1; i >= 0; --i) {
			let hasZero = false //用一个hasZero变量控制0的读法
			for (let k = 3; k >= 0; --k) {
				let cur = +i * 4 + k
				if (cur < len) {
					//如果0在中间或前面，读出来，如果在后面不读出来
					//如果有多个0在前面或中间，只读一个
					if (money[cur] == '0') hasZero = true //遇到0先不读出
					else { //遇到0之后的第一个非0数字证明0在中间位置，需要读出来
						if (hasZero) {
							res += '零'
						}
						res += cap[+money[cur]] + ''
						res += unit[cur % 4] + ''
					}
				}
			}
			res += lunit[i]
		}
		res += '元'
		//小数点后面，只考虑两位数角和分
		if (dotBehind) {
			const dot0 = dotBehind[0]
			const dot1 = dotBehind[1]
			if (dot0 == '0') {
				if (dot1 && dot1 != '0') res += cap[+dot0] + '角' + cap[+dot1] + '分'
				else res += cap[+dot0] + '角'
			} else {
				if (dot1 && dot1 != '0') res += cap[+dot0] + '角' + cap[+dot1] + '分'
				else res += cap[+dot0] + '角'
			}
		}
		return res
	}
}

export default funs